import { useCallback } from 'react';
import { sdk } from 'sdk';
import { CustomPaymentRequestPayload, CustomPaymentResponse } from 'types/payment';
import { useAccount } from 'frontastic';

const useCustomPaymentMethods = () => {
  const { account } = useAccount();

  const getCustomPaymentMethods = useCallback(() => {
    const postPayOption = account?.postPayOption ?? false;
    const customPaymentMethods = [];
    if (postPayOption) {
      customPaymentMethods.push({
        type: 'post_pay',
        name: 'Direct Bill',
      });
    }

    return customPaymentMethods;
  }, [account]);

  const createCustomPayment = useCallback(async (data: CustomPaymentRequestPayload) => {
    const response = await sdk.callAction({
      actionName: 'payment/createCustomPayment',
      payload: data,
    });

    return (response.isError ? response?.error : response.data) as CustomPaymentResponse;
  }, []);

  return { getCustomPaymentMethods, createCustomPayment };
};

export default useCustomPaymentMethods;
