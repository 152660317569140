import { useCallback, useContext } from 'react';
import { sdk } from 'sdk';
import { mutate } from 'swr';
import { PaymentMethod, StripePaymentRequestPayload, StripePaymentResponse } from 'types/payment';

const useStripePayment = () => {
  const getPublishableKey = useCallback(async () => {
    const response = await sdk.callAction({
      actionName: 'payment/stripeConfigPublishableKey',
    });

    return response.isError ? [] : (response.data as PaymentMethod[]);
  }, []);

  const createStripePaymentIntentConfirm = useCallback(async (data: StripePaymentRequestPayload) => {
    const response = await sdk.callAction({
      actionName: 'payment/createStripePaymentIntentConfirm',
      payload: data,
    });

    return (response.isError ? {} : response.data) as StripePaymentResponse;
  }, []);

  const createStripePaymentIntent = useCallback(async (data: StripePaymentRequestPayload) => {
    const response = await sdk.callAction({
      actionName: 'payment/createStripePaymentIntent',
      payload: data,
    });

    mutate('/action/account/getAccount');
    return (response.isError ? {} : response.data) as String;
  }, []);

  const getStripePaymentMethods = async () => {
    const stripePaymentMethods = [];

    stripePaymentMethods.push({
      type: 'stripe',
      name: 'Credit Card',
    });

    return stripePaymentMethods;
  };

  return { getPublishableKey, createStripePaymentIntentConfirm, createStripePaymentIntent, getStripePaymentMethods };
};

export default useStripePayment;
