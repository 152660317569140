import React, { useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@commercetools/frontend-domain-types/product/Product';
import { Transition } from '@headlessui/react';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import Link from 'components/commercetools-ui/atoms/link';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useScrollBlock from 'helpers/hooks/useScrollBlock';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import Image from 'frontastic/lib/image';
import { AddToCartOverlayContextShape } from './types';
import { LineItem } from 'types/cart';

const AddToCartOverlayContext = React.createContext<AddToCartOverlayContextShape>({
  show() {},
  hide() {},
});

const AddToCartOverlayProvider: React.FC = ({ children }) => {
  const router = useRouter();

  const { isTouchDevice } = useTouchDevice();

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'Cart' });

  const { blockScroll } = useScrollBlock();

  const [lineItemByProduct, setLineItemByProduct] = useState<unknown[]>([]);
  const [discardedLineItems, setDiscardedLineItems] = useState<unknown[]>([]);

  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);

  const getLineItemByProduct = (products: Product[], lineItems: LineItem[]) => {
    return products
      .map((product) => {
        const prodAdded = { id: product.productId, name: product.name, lineItems: [] as LineItem[], totalPrice: 0 };
        const lineItemsByProduct = lineItems.filter((li) => product.variants.find((v) => v.sku === li.variant?.sku));
        if (lineItemsByProduct.length) {
          prodAdded.lineItems.push(...lineItemsByProduct);
          prodAdded.totalPrice = lineItemsByProduct
            ?.map((li) => (li?.count || 0) * (li?.variant?.price?.centAmount || 0))
            ?.reduce((acc, current) => (acc += current));
          return prodAdded;
        }
      })
      .filter((p) => p);
  };

  const showTitle = () => {
    if (lineItemByProduct?.length > 0) {
      return formatProductMessage({ id: 'cart.added', defaultMessage: 'Added to Cart' });
    } else if (lineItemByProduct?.length <= 0 && discardedLineItems.length > 0) {
      return formatProductMessage({ id: 'items.not.added', defaultMessage: 'Items not added' });
    }
  };

  const show = useCallback(
    (products: Product[], lineItems: LineItem[], discardedItems: LineItem[]) => {
      if (products.length && lineItems.length) {
        setLineItemByProduct(getLineItemByProduct(products, lineItems));
      }
      if (products.length && discardedItems) {
        setDiscardedLineItems(getLineItemByProduct(products, discardedItems));
      }
      blockScroll(true);
    },
    [blockScroll],
  );

  const hide = useCallback(() => {
    setLineItemByProduct([]);
    setDiscardedLineItems([]);
    blockScroll(false);
  }, [blockScroll]);

  return (
    <>
      {lineItemByProduct?.length > 0 && <Overlay onClick={hide} />}
      <Transition
        show={!!lineItemByProduct?.length || !!discardedLineItems?.length}
        className="fixed bottom-0 z-[9999] w-full overflow-hidden rounded-[20px_20px_0_0] bg-white shadow md:bottom-[unset] md:left-1/2 md:top-1/2 md:w-[90%] md:max-w-[650px] md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-lg"
        enter="transition md:transition-opacity duration-75"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transition md:transition-opacity duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        <div onMouseUp={(e) => e.stopPropagation()}>
          <div className="bg-white p-16 md:px-48 md:py-24">
            <p className="font-NunitoSansBold text-18 leading-[27px] lg:text-22 lg:leading-[33px]">{showTitle()}</p>

            <CloseIcon
              className="absolute right-[18px] top-[16px] h-28 w-28 cursor-pointer fill-secondary-regular stroke-0 md:top-[16px]"
              onClick={hide}
            />

            <div className="mt-22 md:mt-35 lg:mt-30">
              {lineItemByProduct?.length > 0 &&
                lineItemByProduct.map((p: any) => {
                  return (
                    <div className="flex items-center gap-24" key={p.id}>
                      <div className="shrink-0">
                        <Image
                          width={60}
                          height={60}
                          src={p.lineItems?.[0].variant?.images?.[0] ?? '#'}
                          layout="intrinsic"
                          objectFit="contain"
                        />
                      </div>
                      <div className="flex grow items-start justify-between overflow-hidden">
                        <div className="max-w-full overflow-hidden">
                          <span className="block max-w-full truncate font-BarlowSemiCondensedRegular text-18 leading-normal">
                            {p?.name}
                          </span>
                          <span className="mt-12 block font-BarlowSemiCondensedRegular text-14 leading-normal">
                            {p.lineItems
                              ?.map((li: any) => `${li.count} - ${li.variant?.attributes?.size.key}`)
                              ?.join(', ')}
                          </span>
                        </div>
                        <span className="hidden font-BarlowSemiCondensedSemiBold text-18 leading-normal  md:block">
                          {CurrencyHelpers.formatForCurrency(p?.totalPrice ?? {}, router.locale)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              {discardedLineItems?.length > 0 &&
                discardedLineItems.map((p: any) => {
                  return (
                    <>
                      <span className="mt-20 block max-w-full truncate font-BarlowSemiCondensedRegular text-18 leading-normal">
                        {formatProductMessage({ id: 'items.not.added', defaultMessage: 'Items not added' })}
                      </span>
                      <span className="mb-5 block max-w-full truncate font-BarlowSemiCondensedRegular text-12 leading-normal">
                        {formatProductMessage({
                          id: 'items.not.added.disclaimer',
                          defaultMessage:
                            'Disclaimer: This items where not added since you have reached max quantity in your cart for this purchase.',
                        })}
                      </span>
                      <div className="flex items-center gap-24" key={p.id}>
                        <div className="shrink-0">
                          <Image
                            width={60}
                            height={60}
                            src={p.lineItems?.[0].variant?.images?.[0] ?? '#'}
                            layout="intrinsic"
                            objectFit="contain"
                          />
                        </div>
                        <div className="flex grow items-start justify-between overflow-hidden">
                          <div className="max-w-full overflow-hidden">
                            <span className="block max-w-full truncate font-BarlowSemiCondensedRegular text-18 leading-normal">
                              {p?.name}
                            </span>
                            <span className="mt-12 block font-BarlowSemiCondensedRegular text-14 leading-normal">
                              {p.lineItems?.map((li: any) => `Size: ${li.variant?.attributes?.size.key}`)?.join(', ')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="mt-18 flex w-full flex-col gap-16 md:mt-36 md:flex-row-reverse">
              <Link link="/cart" onClick={hide} className="md:flex-1">
                <button className="w-full rounded-lg bg-primary-regular  py-8 font-BarlowSemiCondensedRegular text-lg text-white">
                  {formatCartMessage({ id: 'cart.go', defaultMessage: 'Go to Cart' })}
                </button>
              </Link>
              <div onClick={hide} className="md:flex-1">
                <button className="w-full rounded-lg border border-primary-regular py-8 font-BarlowSemiCondensedRegular text-lg transition hover:border-secondary-regular hover:text-secondary-regular">
                  {formatCartMessage({ id: 'continue.shopping', defaultMessage: 'Continue Shopping' })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <AddToCartOverlayContext.Provider value={{ show, hide }}>{children}</AddToCartOverlayContext.Provider>
    </>
  );
};

export default AddToCartOverlayProvider;

export const useAddToCartOverlay = () => useContext(AddToCartOverlayContext);
