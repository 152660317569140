const localizationMapper = {
// Use with Composable Commerce Project containing USD Pricing
      en: {
    locale: 'en_US@USD',
    useCurrencyInLocale: true,
    currency: 'USD',
    currencyCode: '$',
    countryCode: 'US',
    countryName: 'United States of America',
  },
  // en: {
  //   locale: 'en_GB@GBP',
  //   useCurrencyInLocale: true,
  //   currency: 'GBP',
  //   currencyCode: '£',
  //   countryCode: 'GB',
  //   countryName: 'United Kingdom',
  // },
};

const getLocalizationInfo = (locale) => {
  if (!(locale in localizationMapper)) {
    console.warn(
      `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    );

    return localizationMapper.en;
  }

  return localizationMapper[locale];
};

module.exports = { getLocalizationInfo };
