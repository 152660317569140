import { useCallback } from 'react';
import { sdk } from 'sdk';

const useReports = () => {
  const getReports = useCallback(async (minDate: string, maxDate: string): Promise<any> => {
    const res = await sdk.callAction<any>({
      actionName: 'reports/getReports',
      payload: {
        minDate,
        maxDate,
      },
    });

    return res.isError ? ({} as any) : res.data;
  }, []);

  const getOrdersRegion = useCallback(async (minDate: string, maxDate: string): Promise<any> => {
    const res = await sdk.callAction<any>({
      actionName: 'reports/getOrdersRegion',
      payload: {
        minDate,
        maxDate,
      },
    });

    return res.isError ? ({} as any) : res.data;
  }, []);

  const getOrdersTopProducts = useCallback(async (minDate: string, maxDate: string): Promise<any> => {
    const res = await sdk.callAction<any>({
      actionName: 'reports/getOrdersTopProducts',
      payload: {
        minDate,
        maxDate,
      },
    });

    return res.isError ? ({} as any) : res.data;
  }, []);

  const getOrdersPayment = useCallback(async (minDate: string, maxDate: string): Promise<any> => {
    const res = await sdk.callAction<any>({
      actionName: 'reports/getOrdersPayment',
      payload: {
        minDate,
        maxDate,
      },
    });

    return res.isError ? ({} as any) : res.data;
  }, []);

  const getOrderSummaryYear = useCallback(async (): Promise<any> => {
    const res = await sdk.callAction<any>({
      actionName: 'reports/getOrderSummaryYear',
    });

    return res.isError ? ({} as any) : res.data;
  }, []);

  return { getReports, getOrdersRegion, getOrdersTopProducts, getOrdersPayment, getOrderSummaryYear };
};

export default useReports;
