import { useCallback, useContext, useMemo } from 'react';
import { Discount } from '@commercetools/frontend-domain-types/cart/Discount';
import useSWR, { mutate } from 'swr';
import useI18n from 'helpers/hooks/useI18n';
import { sdk } from 'sdk';
import { Cart } from 'types/cart';
import { Order } from 'types/order';
import { Variant } from 'types/product';
import { revalidateOptions } from 'frontastic';
import { CartDetails, UseCartReturn } from './types';
import { ShippingMethod } from '@commercetools/frontend-domain-types/cart';
import { LineItem } from 'types/cart';
import { GlobalContext } from 'frontastic/context/globalContext';

const useCart = (): UseCartReturn => {
  const extensions = sdk.composableCommerce;
  const { currency } = useI18n();
  const globalContext = useContext(GlobalContext);

  const shippingMethodsResults = useSWR(
    '/action/cart/getShippingMethods',
    extensions.cart.getShippingMethods,
    revalidateOptions,
  );

  const { cart, setCart, getCart } = globalContext;

  const shippingMethods = shippingMethodsResults.data?.isError ? {} : { data: shippingMethodsResults.data?.data };

  const totalItems = (cart as Cart)?.lineItems?.reduce((acc, curr) => acc + (curr.count as number), 0) ?? 0;

  const isEmpty = !!cart?.lineItems && cart?.lineItems?.length === 0;
  const isLoadingCart = !cart;

  const isShippingAccurate = !!cart?.shippingInfo;

  const hasOutOfStockItems = !!cart?.lineItems?.some((lineItem: any) => !lineItem.variant?.isOnStock);

  const transaction = useMemo(() => {
    const cartData = cart;
    if (!cartData?.lineItems?.length)
      return {
        subtotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        discount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        allowance: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        tax: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        shipping: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        total: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      };

    const currencyCode = cartData.sum?.currencyCode ?? currency;
    const fractionDigits = cartData.sum?.fractionDigits ?? 2;

    const totalAmount = cartData.sum?.centAmount as number;
    const subTotalAmount = cartData.lineItems.reduce(
      (acc, curr) => acc + (curr.price?.centAmount || 0) * (curr.count as number),
      0,
    );

    const discountedAmount = cartData.lineItems.reduce(
      (acc, curr) =>
        acc + ((curr.price?.centAmount || 0) * (curr.count as number) - (curr.totalPrice?.centAmount || 0)),
      0,
    );

    const totalTax =
      totalAmount > 0
        ? cartData.taxed?.taxPortions?.reduce((acc, curr) => acc + (curr.amount?.centAmount as number), 0) ?? 0
        : 0;

    const totalShipping = totalAmount > 0 ? cartData?.shippingInfo?.rates[0]?.price?.centAmount : 0;

    return {
      subtotal: {
        centAmount: subTotalAmount,
        currencyCode,
        fractionDigits,
      },
      discount: {
        centAmount: discountedAmount,
        currencyCode,
        fractionDigits,
      },
      allowance: {
        centAmount: 0,
        currencyCode,
        fractionDigits,
      },
      shipping: {
        centAmount: totalShipping,
        currencyCode,
        fractionDigits,
      },
      tax: {
        centAmount: totalTax,
        currencyCode,
        fractionDigits,
      },
      total: {
        centAmount: totalAmount + totalTax,
        currencyCode,
        fractionDigits,
      },
    };
  }, [currency, cart]);

  const addItem = useCallback(async (variant: Variant, quantity: number) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      variant: {
        sku: variant.sku,
        count: quantity,
      },
    };

    const res = await extensions.cart.addItem(payload);
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);
  }, []);

  const bulkAddItems = useCallback(async (lineItems: LineItem[]) => {
    const payload = lineItems;

    const res = await sdk.callAction({ actionName: 'cart/bulkAddToCart', payload: payload });
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);
  }, []);

  const bulkRemoveItems = useCallback(async (lineItems: LineItem[]) => {
    const payload = lineItems;

    const res = await sdk.callAction({ actionName: 'cart/bulkRemoveLineItems', payload: payload });
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);
  }, []);

  const orderCart = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'cart/checkout' });
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);
    mutate('/action/cart/getCart');

    return (res.isError ? {} : res.data) as Order;
  }, []);

  const getOrder = useCallback(async (orderId: Order['orderId']) => {
    const res = await sdk.callAction({ actionName: 'cart/getOrder', payload: { orderId: orderId } });
    getCart();

    return (res.isError ? {} : res.data) as Order;
  }, []);

  const orderHistory = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.getOrderHistory();

    return res.isError ? ([] as Order[]) : (res.data as Order[]);
  }, []);

  const getProjectSettings = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.project.getSettings();

    return res.isError ? {} : res.data;
  }, []);

  const removeItem = useCallback(async (lineItemId: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      lineItem: { id: lineItemId },
    };

    const res = await extensions.cart.removeItem(payload);
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);
  }, []);

  const updateItem = useCallback(async (lineItemId: string, newQuantity: number): Promise<void> => {
    const extensions = sdk.composableCommerce;

    const payload = {
      lineItem: {
        id: lineItemId,
        count: newQuantity,
      },
    };
    const res = await extensions.cart.updateItem(payload);
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);
  }, []);

  const updateCart = useCallback(async (payload: CartDetails): Promise<Cart> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.updateCart(payload);
    const cartUpdated = (res.isError ? {} : res.data) as Cart;
    setCart(cartUpdated);

    return (res.isError ? {} : res.data) as Cart;
  }, []);

  const setShippingMethod = useCallback(async (shippingMethodId: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      shippingMethod: {
        id: shippingMethodId,
      },
    };
    const res = await extensions.cart.setShippingMethod(payload);
    setCart((res.isError ? {} : res.data) as Cart);
  }, []);

  const setCustomShippingMethod = useCallback(async (shippingMethod: ShippingMethod) => {
    const res = await sdk.callAction({ actionName: 'cart/setCustomShippingMethod', payload: shippingMethod });
    const updateResponse = (res.isError ? {} : res.data) as Cart;
    setCart(updateResponse);
  }, []);

  const redeemDiscountCode = useCallback(async (code: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      code: code,
    };
    const res = await extensions.cart.redeemDiscountCode(payload);

    if (!res.isError && (res.data as Cart).cartId) {
    } else {
      throw new Error('code not valid');
    }
  }, []);

  const removeDiscountCode = useCallback(async (discount: Discount) => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.removeDiscountCode({ discountId: discount.discountId as string });
  }, []);

  return {
    cart,
    totalItems,
    isEmpty,
    isShippingAccurate,
    hasOutOfStockItems,
    transaction,
    isLoadingCart,
    addItem,
    bulkAddItems,
    bulkRemoveItems,
    updateCart,
    setShippingMethod,
    setCustomShippingMethod,
    removeItem,
    shippingMethods,
    updateItem,
    orderCart,
    getOrder,
    orderHistory,
    getProjectSettings,
    redeemDiscountCode,
    removeDiscountCode,
    setCart,
    getCart,
  };
};

export default useCart;
