import { useCallback } from 'react';
import { Cart, LineItem } from 'types/cart';
import { Category } from 'types/category';
import { Account } from 'types/account';
import { PaymentMethod } from 'types/payment';

const useAllowedCategoriesChecker = (): UseAllowedCategoriesCheckerReturn => {
  const allowedPaymentMethodWithCartsProducts = useCallback(
    (cart: Cart, account: Account, customPaymentMethodsData: PaymentMethod[]) => {
      const customAllowedPaymentMethods: PaymentMethod[] = [];
      if (account?.allowedPaymentMethodByCategProducts?.length) {
        const cartParentCategories = getLineItemsCategoryKeys(cart.lineItems);
        customPaymentMethodsData?.forEach((cpm) => {
          const categoriesByPaymentMethod = account?.allowedPaymentMethodByCategProducts?.find(
            (apm) => Object.keys(apm)?.[0] === cpm.type,
          );
          const allowedCategories = Object.values(categoriesByPaymentMethod!)?.[0];
          for (const categoryKey of cartParentCategories) {
            if (
              allowedCategories.some((cc) => cc === categoryKey) &&
              !customAllowedPaymentMethods.some((capm) => capm.type === cpm.type)
            ) {
              customAllowedPaymentMethods.push(cpm);
            }
          }
        });
      } else {
        customAllowedPaymentMethods.push(...customPaymentMethodsData);
      }
      return customAllowedPaymentMethods;
    },
    [],
  );

  const isMixeableCategoryWithCartsProducts = useCallback((cart: Cart, categories: Category[], account: Account) => {
    let result = true;
    if (cart?.lineItems?.length && account?.mixeableCategories?.length) {
      const currentCategories = getCurrentCategoryKeys(categories);
      const cartParentCategories = getLineItemsCategoryKeys(cart.lineItems);
      const categoriesToCheck = getMixeableCategoriesFamily(cartParentCategories, account?.mixeableCategories);
      for (const element of categoriesToCheck) {
        const categoryKey = element;
        result = currentCategories.some((cc) => cc === categoryKey);
        if (result) {
          return result;
        }
      }
    }

    return result;
  }, []);

  return { isMixeableCategoryWithCartsProducts, allowedPaymentMethodWithCartsProducts };
};

const getCurrentCategoryKeys = (categories: Category[]): string[] => {
  return categories?.map((cat) => cat?.slug!);
};

const getLineItemsCategoryKeys = (lineItems: LineItem[]): string[] => {
  return Array.from(
    new Set(
      lineItems?.flatMap(
        (li) =>
          li.categories?.flatMap((c) => {
            const keys = c.ancestorsKey!;
            keys.push(c.slug!);
            return keys;
          })!,
      ),
    ),
  );
};

const getMixeableCategoriesFamily = (keys: string[], mixeableCategories: string[]) => {
  const categoriesToCheck: string[] = [];
  keys.forEach((key) => {
    for (let i = 0; i < mixeableCategories.length; i++) {
      const elements = mixeableCategories[i];
      for (let j = 0; j < elements?.length; j++) {
        const element = elements[j];
        if (element === key) {
          categoriesToCheck.push(...elements);
          break;
        }
      }
    }
  });

  return categoriesToCheck;
};
export default useAllowedCategoriesChecker;

export interface UseAllowedCategoriesCheckerReturn {
  isMixeableCategoryWithCartsProducts: (cart: Cart, categories: Category[], account: Account) => boolean;
  allowedPaymentMethodWithCartsProducts: (
    cart: Cart,
    account: Account,
    customPaymentMethodsDatapaymentMethod: PaymentMethod[],
  ) => PaymentMethod[];
}
