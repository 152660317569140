import { useCallback, useContext } from 'react';
import { ProductQuery } from '@commercetools/frontend-domain-types/query/ProductQuery';
import useSWR from 'swr';
import { sdk } from 'sdk';
import { Category } from 'types/category';
import { Inventory } from 'types/inventory';
import { revalidateOptions } from 'frontastic';
import { UseProductReturn } from './types';
import { GlobalContext } from 'frontastic/context/globalContext';

const useProduct = (): UseProductReturn => {
  const globalContext = useContext(GlobalContext);
  const extensions = sdk.composableCommerce;

  const { allCategories, setAllCategories, getAllCategories } = globalContext;

  const categoriesResults = useSWR(
    '/action/product/queryCategories',
    () => extensions.product.queryCategories({ limit: 99 }),
    revalidateOptions,
  );

  const categories = (categoriesResults.data?.isError ? [] : (categoriesResults.data?.data?.items as Category[])) ?? [];

  const query = useCallback(async (productQuery: ProductQuery) => {
    /* To Do: Use SDK instead of current workaround */

    const extensions = sdk.composableCommerce;

    const res = await extensions.product.query({ limit: productQuery.limit, query: productQuery.query });

    return res;

    // const params = new URLSearchParams();

    // if (productQuery.query) params.set('query', productQuery.query);
    // if (productQuery.limit) params.set('limit', productQuery.limit);

    // const res = await fetchApiHub(`/action/product/query?${params.toString()}`, SDK.locale);

    // return { data: res, isError: false } as SDKResponse<Result>;
  }, []);

  const getInventory = useCallback(async (sku: string) => {
    const response = await sdk.callAction({ actionName: 'product/getInventory', query: { sku } });
    return (response.isError ? {} : response.data) as Inventory;
  }, []);

  const getParentsCategories = (categories: Category[], currentCategory: Category): Category[] => {
    let current = currentCategory;
    const parents: Category[] = [];
    while (current?.parentId && current?.depth !== 1) {
      const parent = categories.find((category) => category.categoryId === current?.parentId);
      if (parent) {
        parents.push(parent);
        current = parent;
      } else {
        break;
      }
    }
    parents.push(currentCategory);
    return parents.reverse();
  };

  return { categories, query, getInventory, getParentsCategories, allCategories, getAllCategories, setAllCategories };
};

export default useProduct;
